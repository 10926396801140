import { RIKTNUMMER } from './riktnummer';
import { areaCodeDigitCount } from './utils/areaCodeDigitCount';
import { normalize } from './utils/normalize';
var findNumbersByLength = function (digits, trailingDigits) {
    var areaCodes = RIKTNUMMER.map(function (phoneNumber) { return parseInt(phoneNumber, 10); })
        .map(function (phoneNumber) { return "0" + phoneNumber.toString(); })
        .filter(function (phoneNumber) { return phoneNumber.length === digits; })
        .join('|');
    return new RegExp("^(" + areaCodes + ")\\d{5," + trailingDigits + "}$");
};
export var validator = function (phoneNumber, options) {
    if (options === void 0) { options = { onlyMobile: false }; }
    if (/[a-z]/gi.test(phoneNumber)) {
        return false;
    }
    var normalized = normalize(phoneNumber);
    var areaCodeDigits = areaCodeDigitCount(normalized);
    if (normalized.substr(0, 2) === '07') {
        return /^07(0|2|3|6|9)\d{7}$/.test(normalized);
    }
    if (options.onlyMobile) {
        return false;
    }
    switch (areaCodeDigits) {
        case 2:
            return /^08\d{6,7}$/.test(normalized);
        case 3:
            return findNumbersByLength(3, 7).test(normalized);
        default:
            return findNumbersByLength(4, 6).test(normalized);
    }
};
