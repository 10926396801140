export var RIKTNUMMER = [
    '011 Norrköping',
    '0120 Åtvidaberg',
    '0121 Söderköping',
    '0122 Finspång',
    '0123 Valdemarsvik',
    '0125 Vikbolandet',
    '013 Linköping',
    '0140 Tranås',
    '0141 Motala',
    '0142 Mjölby-Skänninge-Boxholm',
    '0143 Vadstena',
    '0144 Ödeshög',
    '0150 Katrineholm',
    '0151 Vingåker',
    '0152 Strängnäs',
    '0155 Nyköping-Oxelösund',
    '0156 Trosa-Vagnhärad',
    '0157 Flen-Malmköping ',
    '0158 Gnesta',
    '0159 Mariefred',
    '016 Eskilstuna-Torshälla',
    '0171 Enköping',
    '0173 Öregrund-Östhammar',
    '0174 Alunda',
    '0175 Hallstavik-Rimbo',
    '0176 Norrtälje',
    '018 Uppsala',
    '019 Örebro-Kumla',
    '021 Västerås',
    '0220 Hallstahammar-Surahammar',
    '0221 Köping',
    '0222 Skinnskatteberg',
    '0223 Fagersta-Norberg',
    '0224 Sala-Heby',
    '0225 Hedemora-Säter',
    '0226 Avesta-Krylbo',
    '0227 Kungsör',
    '023 Falun ',
    '0240 Ludvika-Smedjebacken',
    '0241 Gagnef-Floda',
    '0243 Borlänge',
    '0246 Svärdsjö-Enviken',
    '0247 Leksand-Insjön',
    '0248 Rättvik',
    '0250 Mora-Orsa',
    '0251 Älvdalen',
    '0253 Idre-Särna',
    '0258 Furudal',
    '026 Gävle-Sandviken',
    '0270 Söderhamn',
    '0271 Alfta-Edsbyn',
    '0278 Bollnäs',
    '0280 Malung',
    '0281 Vansbro',
    '0290 Hofors-Storvik',
    '0291 Hedesunda-Österfärnebo',
    '0292 Tärnsjö-Östervåla',
    '0293 Tierp-Söderfors ',
    '0294 Karlholmsbruk-Skärplinge',
    '0295 Örbyhus-Dannemora',
    '0297 Ockelbo-Hamrånge',
    '0300 Kungsbacka',
    '0301 Hindås',
    '0302 Lerum',
    '0303 Kungälv',
    '0304 Orust-Tjörn',
    '031 Göteborg',
    '0320 Kinna',
    '0321 Ulricehamn',
    '0322 Alingsås-Vårgårda',
    '0325 Svenljunga-Tranemo',
    '033 Borås',
    '0340 Varberg',
    '0345 Hyltebruk-Torup',
    '0346 Falkenberg',
    '035 Halmstad',
    '036 Jönköping-Huskvarna',
    '0370 Värnamo ',
    '0371 Gislaved-Anderstorp',
    '0372 Ljungby',
    '0380 Nässjö',
    '0381 Eksjö',
    '0382 Sävsjö',
    '0383 Vetlanda',
    '0390 Gränna',
    '0392 Mullsjö',
    '0393 Vaggeryd',
    '040 Malmö',
    '0410 Trelleborg',
    '0411 Ystad',
    '0413 Eslöv-Höör',
    '0414 Simrishamn',
    '0415 Hörby',
    '0416 Sjöbo',
    '0417 Tomelilla',
    '0418 Landskrona-Svalöv',
    '042 Helsingborg-Höganäs',
    '0430 Laholm ',
    '0431 Ängelholm-Båstad',
    '0433 Markaryd-Strömsnäsbruk',
    '0435 Klippan-Perstorp',
    '044 Kristianstad',
    '0451 Hässleholm',
    '0454 Karlshamn-Olofström',
    '0455 Karlskrona',
    '0456 Sölvesborg-Bromölla',
    '0457 Ronneby',
    '0459 Ryd',
    '046 Lund',
    '0470 Växjö',
    '0471 Emmaboda',
    '0472 Alvesta-Rydaholm',
    '0474 Åseda-Lenhovda',
    '0476 Älmhult',
    '0477 Tingsryd',
    '0478 Lessebo',
    '0479 Osby',
    '0480 Kalmar ',
    '0481 Nybro',
    '0485 Öland',
    '0486 Torsås',
    '0490 Västervik',
    '0491 Oskarshamn-Högsby',
    '0492 Vimmerby',
    '0493 Gamleby',
    '0494 Kisa',
    '0495 Hultsfred-Virserum',
    '0496 Mariannelund',
    '0498 Gotland',
    '0499 Mönsterås',
    '0500 Skövde',
    '0501 Mariestad',
    '0502 Tidaholm',
    '0503 Hjo',
    '0504 Tibro',
    '0505 Karlsborg',
    '0506 Töreboda-Hova',
    '0510 Lidköping ',
    '0511 Skara-Götene',
    '0512 Vara-Nossebro',
    '0513 Herrljunga',
    '0514 Grästorp',
    '0515 Falköping',
    '0520 Trollhättan',
    '0521 Vänersborg',
    '0522 Uddevalla',
    '0523 Lysekil',
    '0524 Munkedal',
    '0525 Grebbestad',
    '0526 Strömstad',
    '0528 Färgelanda',
    '0530 Mellerud',
    '0531 Bengtsfors',
    '0532 Åmål',
    '0533 Säffle',
    '0534 Ed',
    '054 Karlstad',
    '0550 Kristinehamn ',
    '0551 Gullspång',
    '0552 Deje',
    '0553 Molkom',
    '0554 Kil',
    '0555 Grums',
    '0560 Torsby',
    '0563 Hagfors-Munkfors',
    '0564 Sysslebäck',
    '0565 Sunne',
    '0570 Arvika',
    '0571 Charlottenberg-Åmotfors',
    '0573 Årjäng',
    '0580 Kopparberg',
    '0581 Lindesberg',
    '0582 Hallsberg',
    '0583 Askersund',
    '0584 Laxå',
    '0585 Fjugesta-Svartå',
    '0586 Karlskoga-Degerfors',
    '0587 Nora ',
    '0589 Arboga',
    '0590 Filipstad',
    '0591 Hällefors-Grythyttan',
    '060 Sundsvall-Timrå',
    '0611 Härnösand',
    '0612 Kramfors',
    '0613 Ullånger',
    '0620 Sollefteå',
    '0621 Junsele',
    '0622 Näsåker',
    '0623 Ramsele',
    '0624 Backe',
    '063 Östersund',
    '0640 Krokom',
    '0642 Lit',
    '0643 Hallen-Oviken',
    '0644 Hammerdal',
    '0645 Föllinge',
    '0647 Åre-Järpen',
    '0650 Hudiksvall ',
    '0651 Ljusdal',
    '0652 Bergsjö',
    '0653 Delsbo',
    '0657 Los',
    '0660 Örnsköldsvik',
    '0661 Bredbyn',
    '0662 Björna',
    '0663 Husum',
    '0670 Strömsund',
    '0671 Hoting',
    '0672 Gäddede',
    '0680 Sveg',
    '0682 Rätan',
    '0684 Hede-Funäsdalen',
    '0687 Svenstavik',
    '0690 Ånge',
    '0691 Torpshammar',
    '0692 Liden',
    '0693 Bräcke-Gällö',
    '0695 Stugun ',
    '0696 Hammarstrand',
    '08 Stockholm',
    '090 Umeå',
    '0910 Skellefteå',
    '0911 Piteå',
    '0912 Byske',
    '0913 Lövånger',
    '0914 Burträsk',
    '0915 Bastuträsk',
    '0916 Jörn',
    '0918 Norsjö',
    '0920 Luleå',
    '0921 Boden',
    '0922 Haparanda',
    '0923 Kalix',
    '0924 Råneå',
    '0925 Lakaträsk',
    '0926 Överkalix',
    '0927 Övertorneå',
    '0928 Harads ',
    '0929 Älvsbyn',
    '0930 Nordmaling',
    '0932 Bjurholm',
    '0933 Vindeln',
    '0934 Robertsfors',
    '0935 Vännäs',
    '0940 Vilhelmina',
    '0941 Åsele',
    '0942 Dorotea',
    '0943 Fredrika',
    '0950 Lycksele',
    '0951 Storuman',
    '0952 Sorsele',
    '0953 Malå',
    '0954 Tärnaby',
    '0960 Arvidsjaur',
    '0961 Arjeplog',
    '0970 Gällivare',
    '0971 Jokkmokk',
    '0973 Porjus ',
    '0975 Hakkas',
    '0976 Vuollerim',
    '0977 Korpilombolo',
    '0978 Pajala',
    '0980 Kiruna',
    '0981 Vittangi',
];
export var findByMatch = function (phoneNumber, matches) {
    var area = RIKTNUMMER.filter(function (r) { return r.toLowerCase().includes(phoneNumber); });
    return area.length === 0 ? '' : area[0].replace(matches, '').trim();
};
export var findMatchingAreaCode = function (phoneNumber) {
    return findByMatch(phoneNumber, /\d+/g);
};
export var findMatchingAreaName = function (areaName) {
    return findByMatch(areaName.toLowerCase(), /\D+/g);
};
